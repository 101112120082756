.times {
    display: flex;
    justify-content: space-between;
    align-content: center;
    p {
        margin-bottom: 0;
    }
}

.profile-pic {
    img {
        width: 3rem;
        height: 3rem;
        display: inline-block;
        border-radius: 2rem;
        background: #eee;
        overflow: hidden;
    }
}
